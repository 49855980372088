<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Work Orders (DVSA)" backTo="tools" />

    <main class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md">
        <h3 class="text-2xl mb-4">Create Work Order</h3>

        <form @submit.prevent="onSubmitWorkOrder">
          <div class="flex flex-wrap -my-3 -mx-5 mt-2">
            <div class="w-full flex flex-col">
              <div class="flex flex-wrap">
                <!-- Site number -->
                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm"
                    >Site Number</label
                  >
                  <div class="relative">
                    <select
                      class="bge-input bge-select rounded"
                      required
                      v-model="newWorkOrder.site_number"
                    >
                      <option>client2660</option>
                      <option>client2661</option>
                      <option>client2662</option>
                      <option>client2663</option>
                    </select>
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <svg
                        class="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path
                          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <!-- /Site number -->

                <!-- Vehicle Registration -->
                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm"
                    >Registration #.</label
                  >
                  <input
                    type="text"
                    class="w-full bge-input bge-input-spacing rounded uppercase"
                    required
                    v-model="newWorkOrder.vehicle_registration"
                  />
                </div>
                <!-- /Vehicle Registration -->

                <!-- Vehicle Year -->
                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm"
                    >Registration Year</label
                  >
                  <input
                    type="number"
                    class="w-full bge-input bge-input-spacing rounded uppercase"
                    required
                    v-model="newWorkOrder.vehicle_year"
                  />
                </div>
                <!-- /Vehicle Year -->

                <!-- Vehicle Make -->
                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm">Make</label>
                  <input
                    type="text"
                    class="w-full bge-input bge-input-spacing rounded uppercase"
                    required
                    v-model="newWorkOrder.vehicle_make"
                  />
                </div>
                <!-- /Vehicle Make -->

                <!-- Vehicle Model -->
                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm">Model</label>
                  <input
                    type="text"
                    class="w-full bge-input bge-input-spacing rounded uppercase"
                    required
                    v-model="newWorkOrder.vehicle_model"
                  />
                </div>
                <!-- /Vehicle Model -->

                <!-- Fuel type -->
                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm"
                    >Fuel Type</label
                  >
                  <div class="relative">
                    <select
                      class="bge-input bge-select rounded"
                      required
                      v-model="newWorkOrder.vehicle_fuel_type"
                    >
                      <option>PETROL</option>
                      <option>DIESEL</option>
                    </select>
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <svg
                        class="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path
                          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <!-- Fuel type -->
              </div>
            </div>

            <div class="w-full flex flex-col -mt-5 -mb-3 -mx-5">
              <div class="flex justify-end mt-8 mb-5">
                <button
                  type="submit"
                  class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                >
                  <svg
                    class="stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 2L11 13M22 2l-7 20-4-9-9-4 20-7z" />
                  </svg>
                  <span>Submit Order</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="w-full bg-white p-5 rounded shadow-md mt-6">
        <h3 class="text-2xl mb-4">Work Order History</h3>
        <vue-good-table
          styleClass="vgt-table condensed"
          v-if="viewState == 'Idle'"
          mode="remote"
          :rows="workOrders"
          :columns="columns"
          :pagination-options="{ enabled: false }"
          @on-row-click="onWorkOrderRowSelected"
        />

        <div v-show="viewState == 'Loading'">
          <spinner class="mx-auto my-4" :color="'text-black'" :size="8" />
        </div>

        <div v-if="error">
          <div
            class="bg-red-200 border border-red-500 rounded-lg py-4 px-5 text-red-900"
          >
            <p>
              Couldn't load work order history {{ error ? `(${error})` : `` }}
            </p>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Notification from "@/components/Notification.vue";
import Spinner from "@/components/Spinner.vue";
import axios from "axios";
import _ from "lodash";

export default {
  components: {
    PageHeader,
    Notification,
    Spinner,
  },
  data() {
    return {
      viewState: "Idle",
      error: null,
      workOrders: [],
      newWorkOrder: {
        site_number: "client2660",
        vehicle_fuel_type: "PETROL",
      },
      columns: [
        { label: "Test #", field: "test_number" },
        { label: "Site #", field: "site_number" },
        { label: "Make", field: "vehicle_make" },
        { label: "Model", field: "vehicle_model" },
        { label: "Fuel", field: "vehicle_fuel_type" },
        { label: "Status", field: "order_state" },
        {
          label: "Created",
          field: "created_at",
          formatFn: (value) => {
            return this.$moment.unix(value).format("DD/MM/YYYY hh:mm");
          },
        },
      ],
    };
  },
  async mounted() {
    await this.loadWorkOrders();
  },
  methods: {
    async onSubmitWorkOrder() {
      try {
        this.newWorkOrder = _.mapValues(this.newWorkOrder, (s) => {
          return _.isString(s) ? s.toUpperCase() : s;
        });
        this.newWorkOrder.site_number = this.newWorkOrder.site_number.toLowerCase();

        await axios.post("/v2/api/tools/dvsaworkorders", this.newWorkOrder);
        await this.loadWorkOrders();

        this.newWorkOrder = {
          site_number: "client2660",
          vehicle_fuel_type: "PETROL",
        };

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              { props: { title: "Success", close: onClose } },
              "New work order created"
            );
          },
          { position: "top-right" }
        );
      } catch (error) {
        console.error(error);
        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              { props: { type: "danger", title: "Error", close: onClose } },
              `Create work order failed: ${error.message}`
            );
          },
          { position: "top-right" }
        );
      }
    },
    async loadWorkOrders() {
      this.viewState = "Loading";
      try {
        let result = await axios.get("/v2/api/tools/dvsaworkorders");
        this.workOrders = result.data;
        this.viewState = "Idle";
      } catch (error) {
        this.viewState = "Error";
        this.error = error;
      }
    },
    onWorkOrderRowSelected(selectedRow) {
      this.$router.push({
        name: "tools-workorder-details",
        params: { orderId: selectedRow.row.order_id },
      });
    },
  },
};
</script>
